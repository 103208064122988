svg.loading rect {
  opacity: 0;
}

svg.loading rect:nth-child(1) {
  animation: loadingBar 3s ease-out infinite;
}

svg.loading rect:nth-child(2) {
  animation: loadingBar 3s ease-out infinite;
  animation-delay: 0.6s;
}

svg.loading rect:nth-child(3) {
  animation: loadingBar 3s ease-out infinite;
  animation-delay: 1.0s;
}

@keyframes loadingBar {
  0% {
    transform: translateY(12px);
    opacity: 0;
  }

  50% {
    transform: translateY(0px);
    opacity: 1;
  }

  100% {
    transform: translateY(-12px);
    opacity: 0;
  }
}